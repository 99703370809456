import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { closeHelp } from "../../services/reducer";

import "./help.scss";

export default function Help() {
  const [selectedHelp, setselectedHelp] = useState(-1);

  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleSelect = (e, index) => {
    if (selectedHelp === index) {
        setselectedHelp(-1); // close it if it's already open
    } else {
        setselectedHelp(index); // otherwise, open the clicked item
    }
};

  const handleCloseHelp = () => {
    setselectedHelp(-1);
    dispatch(closeHelp());
  };

  const helpContent = [
    {
        title: "What is the Peter Mac Supporter Wall?",
        content:
        `<p>The Peter Mac Supporter Wall is an interactive honour board recognising those who have made significant donations to the Peter Mac Cancer Foundation to help accelerate cancer research at Peter Mac.</p>
<p>Now that you’re connected, you can use your device like a remote control to start exploring stories about donors and fundraisers, and the impact of their giving.</p>
<p>You can also make a donation to Peter Mac, and your own name will temporarily light up the screen to say Thank You.</p>`
    },
    {
        title: "How can I be featured on the Peter Mac Supporter Wall?",
        content:
        `<p>The wall features the names and stories of generous donors to Peter Mac, and the impact of their giving.</p>
<p>To see your name on the Wall today, follow the prompts to make a donation. Your name will temporarily light up the screen to say Thank You.</p>
<p>To find out how you can be included in the honour board, or to share your story, please contact the Foundation at <a href="mailto:foundation@petermac.org">foundation@petermac.org</a>.</p>`
    },
    {
        title: "What is the Peter Mac Cancer Foundation?",
        content:
        `<p>The Foundation is the fundraising arm of the hospital and plays a vital role in ensuring a sustainable source of income to support Peter Mac’s cancer research for generations to come.</p>
<p>Since its inception in 2002, the Foundation has distributed over $300 million to Peter Mac to support new research opportunities, provide seed funding to help researchers explore bold new research ideas, and provide access to the specialised resources needed to discover cancer cures.</p>
<p>The Foundation office is located on the ground floor of Peter Mac, to the left of the Supporter Wall. Our doors are open from 9am to 5:30pm every weekday.</p>`
    },
    {
        title: "Why support Peter Mac?",
        content:
        `<p>With your support, we can accelerate research, improve care, and make a tangible impact on the lives of people affected by cancer.</p>
<p>Your donation funds cutting-edge research that leads to breakthrough discoveries, new treatments, and earlier detection methods. It also supports patient care programs that provide vital resources to those undergoing treatment.</p>
<p>By joining us in the fight against cancer, you're helping to improve outcomes for patients and their families, and bring new hope for a cancer-free future.</p>`
    },
    {
        title: "How can I support Peter Mac?",
        content:
        `<p>There are so many ways to support Peter Mac:</p>
<ul>
    <li>Make a one-off, regular gift or philanthropic gift</li>
    <li>Give in memory, celebration or thanks</li>
    <li>Include a gift in your Will</li>
    <li>Fundraise for us</li>
    <li>Take on a challenge event</li>
    <li>Volunteer</li>
</ul>
<p>Contact the Foundation at <a href="mailto:foundation@petermac.org">foundation@petermac.org</a> to discuss the best option for you.</p>`
    },
    {
        title: "How can I contact the Foundation?",
        content:
        `<p>The Foundation office is located to the left of the Supporter Wall, and our door is open from 9am to 5:30pm, Monday to Friday. Pop in to say hello!</p>
<p>If outside opening hours, you can find us here:</p>
<p><a href="https://foundation.petermac.org/">https://foundation.petermac.org/</a></p>
<p>Free call: 1800 111 440</p>
<p>Phone: 03 8559 7777</p>
<p>Email: <a href="mailto:foundation@petermac.org">foundation@petermac.org</a></p>`
    },
    {
        title: "How can I provide feedback or report a fault?",
        content:
        `<p>Please visit the Foundation office, which is located to the left of the Supporter Wall.</p>
<p>If it is outside opening hours, please email us at <a href="mailto:foundation@petermac.org">foundation@petermac.org</a></p>`
    }
];


  return (
    <div id="Help" className={`${appState.isHelpOpen ? "open" : "close"}`}>
      <div className="help-main">
        <div className="header">
          <h2>Help</h2>
          <IconButton onClick={handleCloseHelp}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="scroll">
          <div className="list">
            {helpContent.map((item, index) => {
              return (
                <div
                  className={`list-item ${selectedHelp === index ? "open" : ""}`}
                  onClick={(e) => handleSelect(e, index)}
                  key={`HelpItem-${index}`}
                >
                  <div className="item-header">
                    <div className="title">{item.title}</div>
                    <ArrowForwardIosIcon className="icon" />
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
