import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socket from "../../services/socket";

import { closeDonation } from '../../services/reducer';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";

import './donations.scss';

export default function Donations() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleClose = () => {
    dispatch(closeDonation());
  };

  let customDonationURI;

  switch (appState.wallId) {
    case '1':
      customDonationURI = "peter-mac-support-wall";
      break;
    case '2':
      customDonationURI = "peter-mac-supporter-wall";
      break;
    case '3':
      customDonationURI = "petermac-wall-experience-zone-3";
      break;
    default:
      customDonationURI = "peter-mac-support-wall";
      break;
  }

  // Use Effect hook to append the script
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cdn.raisely.com/v3/public/embed.js";
    script.async = true;

    document.body.appendChild(script);

    let timer; // Variable to hold the timer reference
    let elapsedTime = 0; // Variable to track elapsed time


    const sendPingToSocket = () => {
      // Code to send a ping to the socket, e.g., using socket.io or WebSocket API
      // Replace this with your actual implementation
      // console.log("Sending ping to socket...");
      socket.emit("alive", {
        sessionKey: appState.socketSessionKey,
      });
    };

     // Function to start the timer
     const startTimer = () => {
      timer = setInterval(() => {
        sendPingToSocket();
        elapsedTime += 30;

        // Stop the timer after 5 minutes (300 seconds)
        if (elapsedTime >= 600) {
          stopTimer();
        }
      }, 30000);
    };

    // Function to stop the timer
    const stopTimer = () => {
      clearInterval(timer);
    };


    // Start the timer initially
    startTimer();




    // Clean up the timer when the component unmounts or the dependencies change
    return () => {
      document.body.removeChild(script);
      stopTimer();
    };
    

  }, []);

  // Calculate 80% of the device's height
  // const dynamicHeight = window.innerHeight * 0.8;

  return (
    <div id="Donations" onClick={handleClose}>
      <div className="btn-close">
          <IconButton aria-label="Close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      <div
        className="raisely-donate"
        data-campaign-path={customDonationURI}
        data-profile=""
        data-width="100%"
        data-height="509"
      >
      </div>
    </div>
  );
}