import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button} from "@mui/material";

import { closeWelcome } from "../../services/reducer";

import Connecting from "../../assets/images/home-connecting.svg";

import "./welcome.scss";

export default function Welcome() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleStart = () => {
    dispatch(closeWelcome());
  };

  return (
    <div
      id="Welcome"
      className={`${appState.isWelcomeOpen ? "open" : "close"}`}
    >
      <div className="wrapper">
        <img src={Connecting} />
        <p>
          Select a story to start exploring.
        </p>
        <div className="buttons">
          <Button onClick={handleStart} className="btn-start">
            Start
          </Button>
        </div>
      </div>
    </div>
  );
}
