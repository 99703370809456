import BgDonor from "./assets/images/bg-category-donor.svg";
import BgFundraiser from "./assets/images/bg-category-fundraiser.svg";
import BgPatient from "./assets/images/bg-category-patient.svg";
import BgResearch from "./assets/images/bg-category-research.svg";

import IconDonor from "./assets/images/icon-donor.svg";
import IconFundraiser from "./assets/images/icon-fundraiser.svg";
import IconPatient from "./assets/images/icon-patient.svg";
import IconResearch from "./assets/images/icon-research.svg";

export const themeBackgrounds = [BgDonor,BgFundraiser,BgPatient,BgResearch];

export const themeCategories = {
  Donor: {
    background: BgDonor,
    icon: IconDonor,
  },
  Fundraiser: {
    background: BgFundraiser,
    icon: IconFundraiser,
  },
  Patient: {
    background: BgPatient,
    icon: IconPatient,
  },
  Research: {
    background: BgResearch,
    icon: IconResearch,
  },
};

export const TimerHelperTooltip = 15; // 15 seconds
export const TimerDisconnect = 45; // 45 seconds
export const DonationLink = "https://foundation.petermac.org/donate-today";
