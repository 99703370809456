import React from "react";
import { useSelector, useDispatch } from "react-redux";
// import Slider from "react-slick";

import { setSelectedStory, openPlayer, setSelectedCategory, openLookUp } from "../../services/reducer";
// import socket from "../../services/socket";

// import { themeBackgrounds } from "../../const";

import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "./stories.scss";

// import planetSvg from '../../assets/images/bg-category-patient.svg';

export default function Stories() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleClick = (story) => {
    // dispatch(setSelectedCategory(category));
    dispatch(setSelectedStory(story));
    // dispatch(setCurrentSlide(0));
    dispatch(openPlayer());
    dispatch(openLookUp());
  };

  const handleBack = () => {
    dispatch(setSelectedCategory(null));
  }

  return (
    <div id="Stories">
      <div className="header">
        <IconButton className="back-button" aria-label="Search" onClick={() => handleBack()}>
          <ArrowBackIcon />
        </IconButton>
        <h3>{appState.storyPillarMapping[appState.selectedCategory.title] || appState.selectedCategory.title}</h3>
        </div>
        <div className="slides-container">
        {appState.selectedCategory.stories.map((story, index) => {
            return (
              <div className="slide" key={`stories-item-${index}`}>
                <div className="stories-item"
                onClick={() => handleClick(story)}>
                  <div>
                  <h4>{story.title}</h4>
                  <p>{story.subtitle}</p>
                  </div>
                </div>
                </div>
            );
        })}
        </div>
    </div>
  );
}
