import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button} from "@mui/material";

import { closeBooted } from "../../services/reducer";

import Connecting from "../../assets/images/home-connecting.svg";

import "./booted.scss";

export default function Booted() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleClose = () => {
    dispatch(closeBooted());
  };

  return (
    <div
      id="Booted"
      className={`${appState.isBootedOpen ? "open" : "close"}`}
    >
      <div className="wrapper">
        <img src={Connecting} />
        <p>
          You have been disconnected due to too much traffic.
        </p>
        <div className="buttons">
          <Button onClick={handleClose} className="btn-start">
            OK
          </Button>
        </div>
      </div>
    </div>
  );
}
