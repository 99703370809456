import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import socket from "./services/socket";

import { TimerHelperTooltip } from "./const";

import { useGetWallQuery } from "./services/api/wall";
import {
  setStories,
  setTotalStories,
  setCategories,
  setTotalCategories,
  setSocketSessionKey,
  setWallId,
  endSession,
  openBooted
} from "./services/reducer";

// import OnBoarding from "./components/OnBoarding";
import Booted from "./components/Booted";
import Main from "./components/Main";
import SessionEnded from "./components/SessionEnded";
import HelperTooltip from "./components/HelperTooltip";

window.inactiveTime = 0;

function App() {
  const [isHelperTooltipOpen, setIsHelperTooltipOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const wallData = useGetWallQuery();

  const handleInactive = () => {
    setIsHelperTooltipOpen(true);
  };

  const handleHelperTooltipClose = () => {
    setIsHelperTooltipOpen(false);
  };

  const handleTimer = () => {
    if (!window.isPlaying) {
      window.inactiveTime++;
      if (window.inactiveTime === TimerHelperTooltip) {
        handleInactive();
      }
    }
  };

  const startTimer = () => {
    // setInterval(handleTimer, 1000);
  };

  const resetTimer = () => {
    window.inactiveTime = 0;
  };

  window.ontouchstart = resetTimer;

  useEffect(() => {
    startTimer();
    socket.on("connect", () => {});
    const sessionId = searchParams.get("sessionId");
    const zoneId = searchParams.get("zoneId");

    if(sessionId && zoneId) {
      dispatch(setWallId(zoneId));
      dispatch(setSocketSessionKey(sessionId));
    } else {
      dispatch(endSession());
    }
    
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    if (appState.socketSessionKey) {
      socket.emit("userConnect", { sessionKey: appState.socketSessionKey });
  
      const handleDisconnectNotice = (msg) => {
        console.log(msg);
        dispatch(openBooted());
        console.log(appState.socketSessionKey);
        socket.emit("userDisconnect", { sessionKey: appState.socketSessionKey });
        dispatch(endSession());
      };
  
      socket.on("disconnectNotice", handleDisconnectNotice);
  
      // Returning a cleanup function
      return () => {
        socket.off("disconnectNotice", handleDisconnectNotice);
      };
    }
  }, [appState.socketSessionKey]);

  useEffect(() => {
    window.inactiveTime = 0;
  }, [appState.isPlaying]);

  useEffect(() => {
    if (wallData.isSuccess) {

      const sortedStories = [...wallData.data.data.stories].sort((a, b) => {
        return a.title.localeCompare(b.title);
      });

      const pillars = {};
      sortedStories.forEach(story => {
        if (!pillars[story.pillar]) {
          pillars[story.pillar] = [];
        }
        pillars[story.pillar].push(story);
      });
  
      const pillarArray = Object.keys(pillars).map(pillar => {
        return { title: pillar, stories: pillars[pillar] };
      });
      
      dispatch(setStories(sortedStories));
      dispatch(setTotalStories(sortedStories.length));
      dispatch(setCategories(pillarArray));
      dispatch(setTotalCategories(pillarArray.length));
    }
  }, [wallData]);

  // if (appState.isOnboarding) return <OnBoarding />;

  return (
    <>
      {appState.isBootedOpen && <Booted />}
      {appState.isSessionEnded?<SessionEnded />:<Main />}
      <HelperTooltip
        isOpen={isHelperTooltipOpen}
        onClose={handleHelperTooltipClose}
      />
    </>
  );
}

export default React.memo(App);
