import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button } from '@mui/material';


import Header from "../Header";
import Player from "../Player";
// import PlayerMini from "../PlayerMini";
import Featured from "../Featured";
// import List from "../List";
import Help from "../Help";
import Disconnect from "../Disconnect";
import Search from "../Search";
import DonateBanner from "../DonateBanner";

import IconDonate from '../../assets/images/icon-donate-heart-white.svg';

import { IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import { openSearch, openDonation } from "../../services/reducer";

import Logo from "../../assets/images/logo-white.svg";

import "./main.scss";
import Categories from "../Categories";
import Stories from "../Stories";
import Donations from "../Donations";
import Welcome from "../Welcome";
import LookUp from "../LookUp";

function Main() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleOpenSearch = () => {
    dispatch(openSearch());
  };
 
  const handleDonateClick = () => {
    dispatch(openDonation());
  };

  return (
    <div id="Main">
      <Header />
      {/* <DonateBanner /> */}
      {appState.isDonationOpen && <Donations />}
      {appState.isPlayerOpen && <Player />}
      {appState.isPlayerOpen && appState.isLookUpOpen && <LookUp />}
      {/* <PlayerMini /> */}
      <Help />
      <Search />
      <Disconnect />
      {appState.isWelcomeOpen && <Welcome />}
      <div className={`main-body ${appState.isPlayerOpen ? "hide" : "show"}`}>
        {/* {appState.isSearching ? (
          <List />
        ) : ( */}
          <>
            {/* <div className="content"> */}
            {/* <div className="sticky">
              <DonateBanner />
            </div> */}
           
            {/* <h3>Featured Stories</h3> */}
            
            {/* <h3>Choose a Pillar</h3> */}
            {/* {!appState.selectedCategory && <Categories />} */}
            {appState.selectedCategory ? <Stories />:
            <>
              <Featured />
              <Categories />
            </>
            }
            {/* </div> */}
            {/* <Browse /> */}
            {/* <Search /> */}
            {/* <Pillars /> */}
          </>
        {/* )} */}
        <div className="logo">
          <img src={Logo} />
        </div>
      </div>
      <div className="btn-fab">
        {/* <IconButton aria-label="Search" onClick={handleOpenSearch}>
          <SearchIcon />
        </IconButton> */}
        <Button onClick={handleDonateClick}>
          <img src={IconDonate} alt="Donate Icon"/> DONATE NOW
        </Button>
      </div>
    </div>
  );
}


export default React.memo(Main);
