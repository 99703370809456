import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";

import { setPlayerSearchText, setSelectedCategory, setSelectedStory, setCurrentSlide, openPlayer, openLookUp } from "../../services/reducer";
// import socket from "../../services/socket";

// import { themeBackgrounds } from "../../const";

import featuredIcon from '../../assets/images/icon-fundraiser.svg';
import "./featured.scss";

export default function Featured() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const filteredStories = appState.stories.filter(story => 
    story.is_featured && 
    (!appState.selectedCategory || story.pillar === appState.selectedCategory.title)
  );


  const slickConfig = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    centerPadding: '0px',
    swipeToSlide: true,
  };
  
  const handleClick = (story) => {
    const category = appState.categories.find((cat) => cat.title == story.pillar);
    // const storyInd = category.stories.findIndex((st) => st._id == story._id);
    dispatch(setSelectedCategory(category));
    dispatch(setSelectedStory(story));
    // dispatch(setPlayerSearchText(story.title));
    // dispatch(setCurrentSlide(storyInd));
    dispatch(openPlayer());
    dispatch(openLookUp());

    // socket.emit("setStory", {
    //   sessionKey: appState.socketSessionKey,
    //   storyKey: category._id,
    // });
  };

  return (filteredStories.length > 0 &&
    <div id="Featured">
      <h3>Featured Stories</h3>
      <Slider {...slickConfig}>
        {filteredStories.map((story, index) => {
            return (
              <div className="slide" key={`featured-item-${index}`}>
                <div className="featured-item"
                 onClick={() => handleClick(story)}>
                  <div>
                  <span>
                    <img src={featuredIcon} alt={appState.storyPillarMapping[story.pillar] || story.pillar} />
                    {/* {story.pillar} */}
                    {appState.storyPillarMapping[story.pillar] || story.pillar}
                  </span>
                  <h4>{story.title}</h4>
                  {story.subtitle && <p>{story.subtitle}</p>}
                  </div>
                  {/* <div className="planet">
                    <img className="planet" src={themeBackgrounds[index%4]}></img>
                  </div> */}
                </div>
                </div>
            );
        })}
        </Slider>
    </div>
  );
}
