import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// import { Button } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";
// import CheckIcon from "@mui/icons-material/Check";

// import { useGetWallQuery } from "../../services/api/wall";
// import { closeOnboarding } from "../../services/reducer";

import ImgLogo from "../../assets/images/logo-colour.svg";
// import ImgConnecting from "../../assets/images/home-connecting.svg";
// import socket from "../../../src/";

import {
  setSocketSessionKey,
  setWallId
} from "../../services/reducer";

import "./session-ended.scss";

function SessionEnded() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  useEffect(() => {
    dispatch(setWallId(null));
    dispatch(setSocketSessionKey(null));
  });

  return (
    <div
      id="SessionEnded"
      className={`${appState.isSessionEnded ? "open" : "close"}`}
    >
      <div className="logo">
        <img src={ImgLogo} />
      </div>
      <div className="text">
        <h2>You are not connected.</h2>
        <p>Scan a QR code on the Peter Mac Supporter Wall to get started.</p>
      </div>
      <div className="buttons">
       
        {/* <Button className="btn-scan-qr">SCAN QR CODE</Button> */}
        {/* <Button className="btn-error">Don’t see the QR code?</Button> */}
      </div>
    </div>
  );
}

export default SessionEnded;
