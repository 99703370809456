import { api } from "./index";

export const wallApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWall: build.query({
      query: () => `api/v1/wall`,
      providesTags: (_result, _err) => [{ type: "Wall" }],
    }),
  }),
});

export const { useGetWallQuery } = wallApi;
