import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { closeSearch, setSelectedCategory, setSelectedStory, setCurrentSlide, openPlayer, openLookUp } from "../../services/reducer";

import SearchIcon from "@mui/icons-material/Search";

import "./search.scss";

export default function Search() {

  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const [searchValue, setSearchValue] = useState("");
  const [filteredStories, setFilteredStories] = useState(appState.stories);

  useEffect(() => {
    if (appState.isSearchOpen) {
      document.getElementById("Search-List").scrollTop = 0;
      setSearchValue("");
      setFilteredStories(appState.stories);
    }
  }, [appState.isSearchOpen]);

  const handleClick = (story) => {
    const category = appState.categories.find((cat) => cat.title == story.pillar);
    const storyInd = category.stories.findIndex((st) => st._id == story._id);
    dispatch(setSelectedCategory(category));
    dispatch(setSelectedStory(story));
    // dispatch(setPlayerSearchText(story.title));
    // dispatch(setCurrentSlide(storyInd));
    handleCloseSearch();
    dispatch(openPlayer());
    dispatch(openLookUp());
  };

  const handleCloseSearch = () => {
    // setSelectedSearch(-1);
    setSearchValue("");
    dispatch(closeSearch());
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    if(event.target.value.trim() !== "") {
      let searchValues = event.target.value.toLowerCase().split(',').map(value => value.trim()).filter(value => value !== "");
      let searchResult = appState.stories.filter((story) =>
        searchValues.some(value =>
          story.title.toLowerCase().includes(value) ||
          story.pillar.toLowerCase().includes(value)
        )
      );
      setFilteredStories(searchResult);
    } else {
      setFilteredStories(appState.stories);
    }
  };
  
  
  return (
    <div id="Search" className={`${appState.isSearchOpen ? "open" : "close"}`}>
      <div className="search-main">
        <div className="header">
          <h2>Search</h2>
          <IconButton onClick={handleCloseSearch}>
            <CloseIcon />
          </IconButton>
        </div>
        {/* <div id="Search"> */}
          <FormControl variant="standard" className="search-bar">
          <OutlinedInput
            id="outlined-adornment-search"
            type={"text"}
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search"
            autoComplete={"off"}
            endAdornment={
              <InputAdornment position="end">
                  <SearchIcon />
              </InputAdornment>
            }
          />
          </FormControl>
        {/* </div> */}
        <div className="list" id="Search-List">
          {filteredStories.map((story, index) => {
            return (
              <div className="slide" key={`story-item-${index}`}>
                <div className="stories-item"
                  onClick={() => handleClick(story)}>
                  <div className="stories-item-content">
                  <span>
                    {story.pillar}
                  </span>
                  <h4>{story.title}</h4>
                  {story.subtitle && <p>{story.subtitle}</p>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
