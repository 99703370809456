import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button} from "@mui/material";

import { closeLookUp } from "../../services/reducer";

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

import "./look-up.scss";

export default function LookUp() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  useEffect(() => {
    if (appState.isLookUpOpen) {
      const timer = setTimeout(() => {
        dispatch(closeLookUp());
      }, 5000);

      return () => clearTimeout(timer); // cleanup on unmount or on subsequent renders
    }
  }, [appState.isLookUpOpen, dispatch]);

  const handleStart = () => {
    dispatch(closeLookUp());
  };

  return (
    <div
      id="LookUp"
      className={`${appState.isLookUpOpen ? "open" : "close"}`}
    >
      <div className="wrapper">
        <ArrowCircleUpIcon />
        <p>
          Sending your story to the Peter Mac Supporter Wall.<br /><br />You now have control.
        </p>
        <div className="buttons">
          <Button onClick={handleStart} className="btn-start">
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
}
