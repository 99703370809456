import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedCategory } from "../../services/reducer";

import { themeBackgrounds } from "../../const";

import "./categories.scss";

// import planetSvg from '../../assets/images/bg-category-patient.svg';

export default function Categories() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const handleClick = (category) => {

    dispatch(setSelectedCategory(category));
    // dispatch(setSelectedStory(null));
    // dispatch(setCurrentSlide(0));
    // dispatch(openPlayer());
  };
  
  return (
    <div id="Categories">
      <p className="category-header">Select a story to display</p>
        {appState.categories.filter(category => category.stories.length !== 0).map((category, index) => {
            return (
              <div className="slide" key={`categories-item-${index}`}>
                <div className="categories-item"
                onClick={() => handleClick(category)}>
                  <div>
                  <h4>{appState.storyPillarMapping[category.title] || category.title}</h4>
                  <p>Stories: {category.stories.length}</p>
                  </div>
                  <div className="planet">
                    <img className="planet" src={themeBackgrounds[index%4]}></img>
                  </div>
                </div>
                </div>
            );
        })}
    </div>
  );
}
