import React from "react";
import { useSelector, useDispatch } from "react-redux";
import socket from "../../../src/services/socket";

import { Button, IconButton, Typography } from "@mui/material";
// import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { unsetIsDisconnecting, endSession } from "../../services/reducer";

import IconDisconnect from "../../assets/images/icon-disconnect.svg";

import "./disconnect.scss";

export default function Disconnect() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleCancel = () => {
    dispatch(unsetIsDisconnecting());
  };

  const handleLeave = () => {
    socket.emit("userDisconnect", { sessionKey: appState.socketSessionKey });
    dispatch(endSession());
  };

  return (
    <div
      id="Disconnect"
      className={`${appState.isDisconnecting ? "open" : "close"}`}
    >
      <div className="wrapper">
        <img src={IconDisconnect} />
        <p>
          Ready to disconnect from the Wall?
        </p>
        <div className="buttons">
          <Button onClick={handleLeave} className="btn-leave">
            Leave
          </Button>
          <Button onClick={handleCancel} className="btn-cancel">
            Stay
          </Button>
        </div>
      </div>
    </div>
  );
}
