import { createSlice } from "@reduxjs/toolkit";
import mainScrollTop from "../util";

export const appSlice = createSlice({
  name: "appState",
  initialState: {
    isDisconnecting: false,
    // isOnboarding: false,
    isPlaying: false,
    isConnected: true,
    isHelpOpen: false,
    isSearchOpen: false,
    isSearching: false,
    isPlayerOpen: false,
    selectedCategory: null,
    totalCategories: 0,
    // featuredCategories: [], // browsing
    categories: [], // browsing / search result. I.e [{title: title, media: media, story: story, category: category}]
    // pillars: [],
    stories: [],
    playerSearchText: "",
    totalStories: 0,
    listTitle: "",
    isSessionEnded: false, 
    socketSessionKey: null,
    wallId: null,
    selectedStory: null,
    currentSlide: 0, // index of the playing story in the slider, zero based,
    isDonationOpen: false,
    isWelcomeOpen: true,
    isLookUpOpen: false,
    isBootedOpen: false,
    storyPillarMapping: {
      Donor: 'Donor stories',
      Fundraising: 'Fundraising stories',
      Research: 'Impact stories',
      Patient: 'Our supporters'
    }
  },
  reducers: {
    // closeOnboarding: (state) => {
    //   state.isOnboarding = false;
    //   state.isConnected = true;
    //   // state.isPlayerOpen = true;
    //   closePlayer();
    // },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setTotalCategories: (state, action) => {
      state.totalCategories = action.payload;
    },
    setTotalStories: (state, action) => {
      state.totalStories = action.payload;
    },
    setStories: (state, action) => {
      state.stories = action.payload;
    },
    // setPillars: (state, action) => {
    //   state.pillars = action.payload;
    // },
    setSelectedCategory: (state, action) => {
      console.log(state.selectedCategory);
      console.log(action.payload);
      if(action.payload == null){
        state.selectedCategory = null;
      }
      else {
        state.selectedCategory = {
          id: action.payload._id,
          pillar: action.payload.pillar,
          title: action.payload.title,
          stories: action.payload.stories,
        };
      }
    },
    // setFeaturedCategories: (state, action) => {
    //   state.featuredCategories = action.payload;
    // },
    closePlayer: (state) => {
      state.isPlayerOpen = false;
    },
    openPlayer: (state) => {
      state.isPlayerOpen = true;
    },
    setPlayerSearchText: (state, action) => {
      state.playerSearchText = action.payload;
    },
    setIsSearching: (state) => {
      state.isSearching = true;
    },
    unsetIsSearching: (state) => {
      state.isSearching = false;
    },
    setListTitle: (state, action) => {
      state.listTitle = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    openHelp: (state) => {
      state.isHelpOpen = true;
    },
    closeHelp: (state) => {
      state.isHelpOpen = false;
    },
    openSearch: (state) => {
      state.isSearchOpen = true;
    },
    closeSearch: (state) => {
      state.isSearchOpen = false;
    },
    endSession: (state) => {
      state.isSessionEnded = true;
    },
    setIsDisconnecting: (state) => {
      state.isDisconnecting = true;
    },
    unsetIsDisconnecting: (state) => {
      state.isDisconnecting = false;
    },
    setSocketSessionKey: (state, action) => {
      state.socketSessionKey = action.payload;
    },
    setWallId: (state, action) => {
      state.wallId = action.payload;
    },
    setSelectedStory: (state, action) => {
      if(action.payload == null){
        state.selectedStory = null;
      }
      else {
        state.selectedStory = {
          id: action.payload._id,
          title: action.payload.title,
          subtitle: action.payload.subtitle,
          pillar: action.payload.pillar,
          media: action.payload.media,
          main_copy: action.payload.main_copy,
          is_featured: action.payload.is_featured
        };
      }
    },
    setCurrentSlide: (state, action) => {
      state.currentSlide = action.payload;
    },
    openDonation: (state) => {
      state.isDonationOpen = true;
    },
    closeDonation: (state) => {
      state.isDonationOpen = false;
    },
    closeWelcome: (state) => {
      state.isWelcomeOpen = false;
    },
    closeBooted: (state) => {
      state.isBootedOpen = false;
    },
    openBooted: (state) => {
      state.isBootedOpen = true;
    },
    openLookUp: (state) => {
      state.isLookUpOpen = true;
    },
    closeLookUp: (state) => {
      state.isLookUpOpen = false;
    }
  },
});

export const {
  // closeOnboarding,
  // setPillars,
  closePlayer,
  openPlayer,
  // setFeaturedCategories,
  setTotalCategories,
  setSelectedCategory,
  setCategories,
  setStories,
  setTotalStories,
  setPlayerSearchText,
  setIsSearching,
  unsetIsSearching,
  setListTitle,
  setIsPlaying,
  openHelp,
  closeHelp,
  openSearch,
  closeSearch,
  endSession,
  setIsDisconnecting,
  unsetIsDisconnecting,
  setSocketSessionKey,
  setWallId,
  setSelectedStory,
  setCurrentSlide,
  openDonation,
  closeDonation,
  closeWelcome,
  openLookUp,
  closeLookUp,
  closeBooted,
  openBooted
} = appSlice.actions;

export default appSlice.reducer;
