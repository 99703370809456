import React from "react";
import { useDispatch } from "react-redux";

import { IconButton, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SearchIcon from '@mui/icons-material/Search';

import { openHelp, setIsDisconnecting, openSearch } from "../../services/reducer";

import IconWall from "../../assets/images/icon-wall-3.svg";


import "./header.scss";

export default function Header() {
  const dispatch = useDispatch();

  const handleOpenHelp = () => {
    dispatch(openHelp());
  };

  const handleOpenSearch = () => {
    dispatch(openSearch());
  };

  const handleDisconnect = () => {
    dispatch(setIsDisconnecting());
  };

  return (
    <div id="Header">
      <div className="start-buttons">
      <div className="btn-disconnect">
        <IconButton aria-label="Disconnect" onClick={handleDisconnect}>
          <PowerSettingsNewIcon />
        </IconButton>
      </div>
      </div>
      <div className="center-buttons">
      <div className="text-status">
        <Typography variant="h5">
          Supporter Wall
          <br />
          <span>CONNECTED</span>
        </Typography>
      </div>
      <div className="icon-wall">
        <img src={IconWall} />
      </div>
      </div>
      <div className="end-buttons">
        <div className="btn-search">
          <IconButton aria-label="Search" onClick={handleOpenSearch}>
            <SearchIcon />
          </IconButton>
        </div>
        <div className="btn-help">
          <IconButton aria-label="Help" onClick={handleOpenHelp}>
            <QuestionMarkIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
