import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

import { Button } from "@mui/material";

// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { closePlayer, setIsPlaying, setCurrentSlide, endSession, setSelectedStory, setSelectedCategory } from "../../services/reducer";
import socket from "../../services/socket";
import mainScrollTop from "../../services/util";


import IconDonate from "../../assets/images/icon-donate-heart.svg";
import IconBrowse from "../../assets/images/icon-browse.svg";
import IconNext from "../../assets/images/icon-navigation-left.svg";
import IconPrevious from "../../assets/images/icon-navigation-right.svg";

// import HomeConnection from "../../assets/images/home-connecting.svg";
import IconPlay from '../../assets/images/icon-play.svg'    
import IconPause from "../../assets/images/pausebtn.svg";
// import SearchIcon from "@mui/icons-material/Search";

// import {
//   IconButton,
//   Input,
//   FormControl,
//   OutlinedInput,
//   InputAdornment,
// } from "@mui/material";

import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "./player.scss";

let storyCountdown = 0;
let StoryCountdownTimer;
let isPaused = 0;
const defaultPlaytime = 10;

let customDonationURI;

export default function Player() {

  const sliderElement = useRef();
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  
  const [playerProgress, setPlayerProgress] = useState(0);
  const [storyPlayTime, setStoryPlayTime] = useState(0);

  const [playerTimer, setPlayerTimer] = useState(0);

  const [searchValue, setSearchValue] = useState(appState.playerSearchText);
  // const [filteredStories, setFilteredStories] = useState([]);

  // const isCategorySelected = appState.selectedCategory !== null;
  const isStorySelected = appState.selectedStory !== null;
  
  switch (appState.wallId) {
    case '1':
      customDonationURI = "https://peter-mac-support-wall.raisely.com/";
      break;
    case '2':
      customDonationURI = "https://peter-mac-supporter-wall.raisely.com/";
      break;
    case '3':
      customDonationURI = "https://petermac-wall-experience-zone-3.raisely.com/";
      break;
    default:
      customDonationURI = "https://petermac-wall-experience-zone-1.raisely.com/";
      break;
  }

  const getRandomStories = (stories, excludeStory, count) => {
    // filter out the excluded story
    const filteredStories = stories.filter(story => story._id !== excludeStory.id);

    // shuffle the array
    for (let i = filteredStories.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [filteredStories[i], filteredStories[j]] = [filteredStories[j], filteredStories[i]];
    }

    // return the first 'count' elements
    return filteredStories.slice(0, count);
  };
  const randomStories = getRandomStories(appState.selectedCategory.stories, appState.selectedStory, 5);

  // const filterStories = (searchValue) => {
  //   if (appState.selectedCategory && appState.selectedCategory.stories) {
  //     const filtered = appState.selectedCategory.stories.filter((story) =>
  //       story.title.toLowerCase().includes(searchValue.toLowerCase()) && story.main_copy !== ""
  //     );
  //     setFilteredStories(filtered);
  //   } else {
  //     setFilteredStories([]);
  //   }
  // };
  
  // useEffect(() => {
  //   if (appState.selectedCategory && appState.selectedCategory.stories) {
  //     const nonEmptyMainCopyStories = appState.selectedCategory.stories.filter((story) => story.main_copy !== "");
  //     setFilteredStories(nonEmptyMainCopyStories);
  //   } else {
  //     setFilteredStories([]);
  //   }
  // }, [appState.selectedCategory]);

  // useEffect(() => {
  //   filterStories(searchValue);
  //   if (sliderElement.current) {
  //     sliderElement.current.slickGoTo(0);
  //   }
  // }, [searchValue]);
    

  // const handleChange = (prop) => (event) => {
  //   setSearchValue(event.target.value);
  // };

  // const handleClick = () => {
  //   filterStories(searchValue);
  // };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     filterStories(searchValue);
  //   }
  // };

  const handleBackPlayer = () => {
    dispatch(closePlayer());
    dispatch(setSelectedStory(null));
    // dispatch(setSelectedCategory(null));
    // mainScrollTop();
  }; 

  const handleClosePlayer = () => {
    dispatch(closePlayer());
    dispatch(setSelectedStory(null));
    dispatch(setSelectedCategory(null));
    mainScrollTop();
  }; 

  const BtnBrowse = () => {
    return (
      <Button className="btn-browse" onClick={handleClosePlayer}>
        <img src={IconBrowse} alt="Browse" /> Home
      </Button>
    );
  };

  const handleNext = () => {
    // document.querySelector(".slick-next").click();
    socket.emit("nextPage", {
      sessionKey: appState.socketSessionKey,
    });
  };
  const handlePrev = () => {
    // document.querySelector(".slick-prev").click();
    socket.emit("prevPage", {
      sessionKey: appState.socketSessionKey,
    });
  };

  const Navigation = () => {
    return (
      <div className="navigation">
        <BtnBrowse />
      </div>
    );
  };

  // const Navigation2 = () => {
  //   return (
  //     <div className="navigation2">
  //       <IconVideo />

  //       <Button className="btn-previous" onClick={handlePrev}>
  //         <img src={IconPrevious} alt="Previous" />
  //       </Button>
  //       <Button className="btn-next" onClick={handleNext}>
  //         <img src={IconNext} alt="Next" />
  //       </Button>
  //     </div>
  //   );
  // }

  const slickConfig = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false, 
    variableWidth: true,       
  };

  // const onAfterChange = (current) => {
  //   dispatch(setCurrentSlide(current));
  //   if(filteredStories.length > 0) {
  //     handlePlay(filteredStories[current]);
  //   }
  //   // console.log("wgl");
  // };

  function IconVideo() {
    return (
      
      <>
      <img className="playbtn" src={IconPlay} onClick={() => handleClickPlay()} alt="Play Button"/>

      <img className="pausebtn" src={IconPause} onClick={() => handleClickPlay()} alt="Play Button"/>
      </>
      
    );
  }

  const handlePlay = (story) => {
    socket.emit("setStory", {
      sessionKey: appState.socketSessionKey,
      storyKey: story.id
    });
    document.querySelector(".playCont").style.display = "none";
  };

  const handleClickStory = (story) => {
    // if (index == appState.currentSlide) {
    //   socket.emit("setStory", {
    //     sessionKey: appState.socketSessionKey, storyKey: story._id, file_slug: '/video_url_here.mp4'
    //   });
    //   document.querySelector(".playbtn").style.display = "none";
    //   document.querySelector(".pausebtn").style.display = "none";
    //   document.querySelector(".playCont").style.display = "none";
    // }
    // else{
      dispatch(setSelectedStory(story));
      // sliderElement.current?.slickGoTo(index, true);
      // appState.currentSlide = index; 
      // console.log("hello");
    // }
  };

  const handleClickPlay = (story, index) => {
    // console.log("helo");
      if (window.isPlaying !== 1) {
        if (isPaused === 0) {
          // const playTime = defaultPlaytime;
          // setStoryPlayTime(playTime); // story.viewtimeinseconds in seconds
          // clearTimeout(playerTimer);
          // setPlayerTimer(setTimeout(handleStoryEnd, playTime * 1000));
          // clearTimeout(StoryCountdownTimer);

          isPaused = 0;
          // storyCountdown = playTime;
          // StoryCountdownTimer = setInterval(handleStoryCountdown, 1000);

          window.isPlaying = 1;

          // setPlayerProgress(0);
          // dispatch(setIsPlaying(story._id));

          socket.emit("playVideo", {
            sessionKey: appState.socketSessionKey,
          });

          document.querySelector(".playCont").style.display = "flex";
          document.querySelector(".playbtn").style.display = "none";
          document.querySelector(".pausebtn").style.display = "block";
        } else {
          // resume
          // setPlayerProgress(100);
          // setStoryPlayTime(storyCountdown);
          // setPlayerTimer(setTimeout(handleStoryEnd, storyCountdown * 1000));
          // StoryCountdownTimer = setInterval(handleStoryCountdown, 1000);
          window.isPlaying = 1;
          isPaused = 0;
          socket.emit("playVideo", {
            sessionKey: appState.socketSessionKey,
          });
          document.querySelector(".playCont").style.display = "flex";
          document.querySelector(".playbtn").style.display = "none";
          document.querySelector(".pausebtn").style.display = "block";
        }
      } else {
        handleStoryPause();
        document.querySelector(".playCont").style.display = "flex";
        document.querySelector(".pausebtn").style.display = "none";
        document.querySelector(".playbtn").style.display = "block";
      }
  }

  const handleStoryCountdown = () => {
    storyCountdown--;
  };

  const handleStoryEnd = () => {
    dispatch(setIsPlaying(false));
    setPlayerProgress(0);
    clearTimeout(StoryCountdownTimer);
    window.isPlaying = 0;
  };

  const handleStoryPause = () => {
    const originalPlayTime = defaultPlaytime; // appState.selectedCategory.stories[currentSlide].viewtimeinseconds;
    const playedPercentage =
      ((originalPlayTime - storyCountdown) / originalPlayTime) * 100;
    setPlayerProgress(playedPercentage);
    clearTimeout(playerTimer);
    clearTimeout(StoryCountdownTimer);
    window.isPlaying = 0;
    isPaused = 1;
    socket.emit("pauseVideo", {
      sessionKey: appState.socketSessionKey,
    });
  };

  
  useEffect(() => {
    socket.on("VideoPageFound", (msg) => {
      if(msg === appState.wallId){
        setTimeout(() => {
          document.querySelector(".playCont").style.display = "flex";
          document.querySelector(".playbtn").style.display = "block";
        }, 500);
      }
    }); 
  
    socket.on("NoVideoPageFound", (msg) => {
      if(msg === appState.wallId){
        socket.emit("pauseVideo", {
          sessionKey: appState.socketSessionKey,
        });
        setTimeout(() => {
          document.querySelector(".playCont").style.display = "none";
          document.querySelector(".playbtn").style.display = "none";
          document.querySelector(".pausebtn").style.display = "none";
        }, 250);

      }
    });  

    socket.on("UserDisconnected", (msg) => {
      if(msg.ZoneId === appState.wallId){
        // Leave here.
          dispatch(endSession());
      }
    });
  }, [appState.wallId, appState.selectedStory]);  

  useEffect(() => {
    setPlayerProgress(100);
    // sliderElement.current?.slickGoTo(appState.currentSlide);
    // if(appState.currentSlide != null && appState.isPlayerOpen && isCategorySelected){
    //   // onAfterChange(appState.currentSlide);
    //   if(filteredStories.length > appState.currentSlide){
    //     handlePlay(filteredStories[appState.currentSlide]);
    //   }
    // }
    if(appState.isPlayerOpen && isStorySelected){
      // onAfterChange(appState.currentSlide);
      if(appState.selectedStory){
        handlePlay(appState.selectedStory);
      }
    }

  }, [appState.isPlaying, appState.selectedStory]);

  return (
    <div
      id="Player"
      className={`${appState.isPlayerOpen ? "open" : "close"} ${
        isStorySelected && "view-category"
      }`}
    >
      <div className="header">
        <IconButton className="back-button" aria-label="Search" onClick={() => handleBackPlayer()}>
          <ArrowBackIcon />
        </IconButton>
        <h3>{appState.storyPillarMapping[appState.selectedCategory.title] || appState.selectedCategory.title}</h3>
        </div>
      {isStorySelected ? (
        <div className="category">
          <div className="hide-on-small-screen">
            <p className="featured-header">
              More from {appState.storyPillarMapping[appState.selectedStory.pillar] || appState.selectedStory.pillar}
            </p>
            <Slider
              {...slickConfig}
              id="PlayerSlider"
              // afterChange={onAfterChange}
              ref={sliderElement}
              key={randomStories.length}
            >
              {randomStories.map((story, index) => {
                return (
                  <div className="slide" key={`featured-item-${index}`}>
                  <div className="featured-item"
                  onClick={() => handleClickStory(story)}>
                    <div>
                    <h4>{story.title}</h4>
                    {/* {story.subtitle && <p>{story.subtitle}</p>} */}
                    </div>
                  </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <h2 className="specialH2">{appState.selectedStory.title}</h2>
          <h5 className="specialH5">{appState.selectedStory.subtitle}</h5>
          <div className="explaination">
            {/* <h3>
              Your selection is now visible on the<br/>Supporter Wall in front of you.
            </h3> */}
            
            <div className="btnCont">
              <Button className="btn-previous" onClick={handlePrev}>
                <img src={IconPrevious} alt="Previous" />
              </Button>
              <p className="subtext">
                Use the arrows to view more<br/>content related to your selection
              </p>
              <Button className="btn-next" onClick={handleNext}>
                <img src={IconNext} alt="Next" />
              </Button>
            </div>

            <div className="playCont">
              <h4>
                Use the play button to control the video
              </h4>
              <IconVideo />
            </div>
          </div>
          {/* <Navigation2 /> */}
          {/* { appState.isPlayerOpen && <><Slider
            {...slickConfig}
            id="PlayerSlider"
            afterChange={onAfterChange}
            ref={sliderElement}
            key={filteredStories.length}
          >
            {filteredStories.map((story, index) => {
              return (
                <div className="story-wrapper" key={`Player-Stories-${index}`} >
                  {story._id === appState.isPlaying && (
                    <CircularProgressbar
                      value={playerProgress}
                      id="CircularProgressbar"
                      styles={buildStyles({
                        pathTransitionDuration: storyPlayTime,
                      })}
                    />
                  )}
                  <div
                    className="story-item" onClick={() => handleClickStory(story, index)}
                  >
                    <h3
                      style={{
                        fontSize: `${
                          story.title.length > 60
                            ? "4vw"
                            : story.title.length > 37
                            ? "4.6vw"
                            : "4.6vw"
                        }`,
                      }}
                    >
                      {story.title}
                    </h3>
                  </div>
                </div>
              );
            })}
          </Slider>
              <div className="search">
              <FormControl variant="standard" className="search-bar">
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={"text"}
                  value={searchValue}
                  onChange={handleChange("password")}
                  placeholder="Search"
                  autoComplete={"off"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClick} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  onKeyPress={handleKeyPress}
                />
              </FormControl>
            </div></>
          } */}
        </div>
      ) : (
        <div className="intro">
          <BtnBrowse />
          <p className="text-highlight" onClick={handleClosePlayer}>
            No Stories are selected, head back home.
          </p>
        </div>
      )}
      <Navigation />
      {/* <div className="btn-donate-wrapper">
        <Button className="btn-donate" href={customDonationURI} target="_blank">
          <img src={IconDonate} alt="Donate" />
          DONATE
          <br />
          NOW
        </Button>
      </div> */}
      {isStorySelected ? (
        <></>
      ) : (
        <div className="loading">
          <div className="title"></div>

          <div className="stories"></div>
        </div>
      )}      
    </div>
  );
}
